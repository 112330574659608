var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('DataFilter',{attrs:{"filterProps":_vm.filterProps,"items":_vm.dados,"paginate":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-data-table',{staticClass:"clientesTable",attrs:{"item-key":"idcliente","headers":_vm.headers,"items":items,"loading":items.length == 0,"footer-props":_vm.footprops,"loading-text":"Carregando... Aguarde um momento"},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/clientes/detalhes/" + (item['hash'])),"tag":"a"}},[_vm._v(_vm._s(item["nome"]))])]}},{key:"item.statusbase",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item["statusbase"] == 1 ? "Ativo" : item["statusbase"] == 0 ? "Inativo - sem acesso" : "Inativo - com acesso somente leitura"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openStatusDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)})]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"370px","transition":"dialog-transition"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Alterar Status")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openStatusDialog()}}},[_c('v-icon',[_vm._v("fa-close")])],1)],1),_c('v-card-subtitle',{staticClass:"pt-2"},[_c('p',[_vm._v("Selecione o novo status do cliente")]),_c('v-select',{attrs:{"items":_vm.itemsStatus,"hide-details":"","item-text":"text","item-value":"value","label":"Status"},model:{value:(_vm.selectedItem.statusbase),callback:function ($$v) {_vm.$set(_vm.selectedItem, "statusbase", $$v)},expression:"selectedItem.statusbase"}})],1),_c('v-card-actions',{staticClass:"pa-0 pb-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.updateStatus()}}},[_vm._v("Salvar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }