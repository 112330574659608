<template lang="pug">
v-container(fill-height, fluid, grid-list-xl)
  v-layout(justify-center, wrap)
    v-flex(md12)
      DataFilter(:filterProps="filterProps", :items="dados", :paginate="false")
        template(v-slot:default="{ items }")
          v-data-table.clientesTable(
            item-key="idcliente",
            :headers="headers",
            :items="items",
            :loading="items.length == 0",
            :footer-props="footprops",
            loading-text="Carregando... Aguarde um momento"
          )
            template(v-slot:item.nome="{ item }")
              router-link(:to="`/clientes/detalhes/${item['hash']}`", tag="a") {{ item["nome"] }}

            template(v-slot:item.statusbase="{ item }")
              span {{ item["statusbase"] == 1 ? "Ativo" : item["statusbase"] == 0 ? "Inativo - sem acesso" : "Inativo - com acesso somente leitura" }}

            template(v-slot:item.actions="{ item }")
              v-btn(icon, color="primary", @click="openStatusDialog(item)")
                v-icon(small) fa-edit

  v-dialog(width="370px", v-model="showDialog", transition="dialog-transition")
    v-card
      v-card-title
        span Alterar Status
        v-spacer
        v-btn(icon, @click="openStatusDialog()")
          v-icon fa-close
      v-card-subtitle.pt-2
        p Selecione o novo status do cliente
        v-select(
          :items="itemsStatus",
          hide-details,
          item-text="text",
          item-value="value",
          label="Status",
          v-model="selectedItem.statusbase"
        )
      v-card-actions.pa-0.pb-3
        v-spacer
        v-btn(outlined, color="primary", @click="updateStatus()") Salvar
</template>

<script>
import { mapMutations } from "vuex";
import { clientes as api } from "@/api";

export default {
  data: () => ({
    showDialog: false,
    filterProps: { includeSearch: true, items: [] },
    dados: [],
    headers: [
      { value: "idcliente", text: "ID", width: 70, sortable: 1 },
      { value: "nome", text: "Cliente", link: 1, sortable: 1 },
      { value: "tipoempresa", text: "Caracterização", width: 280, sortable: 1 },
      { value: "uf", text: "UF", width: 100, sortable: 1 },
      { value: "ip", text: "IP", width: 100 },
      { value: "versao", text: "Versão", width: 120, sortable: 1 },
      { value: "statusbase", text: "Status", width: 200, sortable: 1 },
      { value: "softhouse", text: "Softhouse", width: 200, sortable: 1 },
    ],
    itemsStatus: [
      { text: "Ativo", value: 1 },
      { text: "Inativo - sem acesso", value: 0 },
      { text: "Inativo - com acesso somente leitura", value: 2 },
    ],
    selectedItem: { statusbase: 1 },
    footprops: {
      itemsPerPageOptions: [50, 100, 200, 300, 500, -1],
      showCurrentPage: true,
      showFirstLastPage: true,
      itemsPerPageAllText: "Todos",
      itemsPerPageText: "Itens por página",
    },
  }),
  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    setFilters() {
      this.filterProps.items = [];

      this.filterProps.items.push({
        text: "Status",
        type: "select",
        values: [
          { field: "statusbase", text: "Ativo", value: 1 },
          { field: "statusbase", text: "Inativo - sem acesso", value: 0 },
          {
            field: "statusbase",
            text: "Inativo - com acesso somente leitura",
            value: 2,
          },
        ],
      });

      this.filterProps.items.push({
        text: "Softhouse",
        type: "select",
        values: this.dados.map((item) => ({
          field: "softhouse",
          text: item.softhouse,
          value: item.softhouse,
        })),
      });
    },

    isAdmin() {
      const roles = JSON.parse(
        JSON.stringify(this.$store.state.auth.user.roles)
      );
      const adminRole = [];

      roles.forEach((role) => {
        if (role.name.toUpperCase() === "GERENTE") {
          adminRole.push(role);
        }
      });

      return adminRole.length > 0;
    },

    openStatusDialog(item) {
      if (this.showDialog) {
        this.selectedItem = { statusbase: 1 };
        this.showDialog = false;
      } else {
        this.selectedItem = { ...item };
        this.showDialog = true;
      }
    },

    async updateStatus() {
      try {
        await api.updateStatus(this.selectedItem.idcliente, {
          statusbase: this.selectedItem.statusbase,
        });
        this.dados.forEach((item, index) => {
          if (item.idcliente == this.selectedItem.idcliente) {
            this.dados[index].statusbase = this.selectedItem.statusbase;
          }
        });
        this.$store.dispatch("alert/success", "Status alterado com sucesso", {
          root: false,
        });
        this.openStatusDialog();
      } catch (e) {
        this.$store.dispatch(
          "alert/error",
          "Ocorreu algum problema ao alterar o status",
          { root: false }
        );
      }
    },
  },
  async mounted() {
    this.isControl(true);
    this.setTitle("Clientes");
    api.refreshAuthProd(this.$store.state.auth);

    this.showLoader("Carregando clientes...");

    try {
      this.dados = await api.getAll();
      this.setFilters();
    } catch (e) {
      this.$toast.error("Ocorreu algum problema ao buscar as informações");
    } finally {
      this.hideLoader();
    }

    if (this.isAdmin()) {
      this.headers.push({
        value: "actions",
        text: "#",
        align: "center",
        width: 60,
        sortable: 0,
      });
    }
  },
  created() {
    this.$store.dispatch("auth/verify");
  },
  computed: {
    filteredData() {
      const filters = this.search.split("|");
      let data = this.dados;

      for (const filter of filters) {
        const itemFilter = filter.split(":");

        if (itemFilter.length < 2) {
          break;
        }

        data = data.filter(
          (item) =>
            `${item[itemFilter[0].trim()]}`.indexOf(itemFilter[1].trim()) > -1
        );
      }

      return data;
    },
  },
};
</script>

<style>
.clientesTable .v-data-table__wrapper {
  height: calc(100vh - 233px);
  overflow-y: auto;
}
</style>
